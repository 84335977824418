import React from "react"
import PropTypes from "prop-types"
import { Button } from "../inputs"
import NavBarMenu from "./NavBarMenu"
import NavPopup from "./NavPopup"
import { Link } from "gatsby"
import { useScroll } from "ahooks"

const NavBar = ({
  title,
  bgColor,
  logo,
  menus,
  actionPrimary,
  actionSecondary,
}) => {
  const { top: hasScrolled } = useScroll()

  return (
    <div
      className={`${
        hasScrolled ? "shadow-sm bg-white" : ""
      } transition duration-300 ease-in font-display`}
    >
      <div
        className={`container px-4 mx-auto ${
          hasScrolled ? "bg-white" : "bg-" + bgColor
        } transition duration-300 ease-in`}
      >
        <nav className="flex justify-between items-center py-6">
          <h1 className="hidden">{title}</h1>
          <Link className="text-3xl font-semibold leading-none w-24" to="/">
            {logo}
          </Link>
          <div className="lg:hidden">
            <NavPopup
              menus={menus}
              actionPrimary={actionPrimary}
              actionSecondary={actionSecondary}
            />
          </div>
          <div className="hidden lg:flex">
            <div className="flex items-center w-auto space-x-3 justify-around mr-3">
              {menus.map(({ title, href, items }) => (
                <NavBarMenu
                  key={title}
                  title={title}
                  href={href}
                  items={items}
                />
              ))}
            </div>
            <div className="block">
              {actionSecondary && (
                <Button
                  component={Link}
                  to={actionSecondary.href}
                  variant="outlined"
                  className="mr-3"
                >
                  {actionSecondary.title}
                </Button>
              )}
              <Button component={Link} to={actionPrimary.href}>
                {actionPrimary.title}
              </Button>
            </div>
          </div>
        </nav>
      </div>
    </div>
  )
}

NavBar.propTypes = {
  title: PropTypes.string.isRequired,
  logo: PropTypes.node.isRequired,
  bgColor: PropTypes.oneOf(["white", "silver-light"]),
  menus: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      href: PropTypes.string,
      items: PropTypes.arrayOf(
        PropTypes.shape({
          title: PropTypes.string.isRequired,
          description: PropTypes.string,
          href: PropTypes.string.isRequired,
          icon: PropTypes.string.isRequired,
        })
      ),
    })
  ),
  actionPrimary: PropTypes.shape({
    title: PropTypes.string.isRequired,
    href: PropTypes.string,
  }),
  actionSecondary: PropTypes.shape({
    title: PropTypes.string.isRequired,
    href: PropTypes.string,
  }),
}

NavBar.defaultProps = {
  bgColor: "white",
}

export default NavBar
