import React from "react"
import PropTypes from "prop-types"

const getBorderColor = type => {
  switch (type) {
    case "error":
      return "ring-semantic-red"
    default:
      return "ring-gold"
  }
}

const getTextColor = type => {
  switch (type) {
    case "error":
      return "text-semantic-red"
    default:
      return "text-gold"
  }
}

const Message = ({ className, type, children }) => {
  return (
    <div
      className={`${className} py-3 px-4 text-xs font-semibold ring-2 ${getTextColor(
        type
      )} ${getBorderColor(type)} rounded`}
    >
      {children}
    </div>
  )
}

Message.propTypes = {
  className: PropTypes.string,
  type: PropTypes.oneOf(["success", "error"]),
  children: PropTypes.any,
}

Message.defaultProps = {
  className: ``,
  type: `success`,
}

export default Message
