import React from "react"
import PropTypes from "prop-types"
import { Popover, Transition } from "@headlessui/react"
import { ChevronDownIcon } from "@heroicons/react/solid"
import { Fragment } from "react"
import { Icon } from "../display"

const classesMenu = `rounded inline-block text-sm text-left font-semibold leading-none focus:outline-none focus:ring-2 transition duration-300 ease-in-out
cursor-pointer text-jet group hover:bg-gold-lighter active:bg-gold-light focus:ring-gold-light focus:ring-opacity-50 
inline-flex items-center`

const NavBarMenu = ({ title, href, items }) => {
  return items ? (
    <MenuPopover title={title} items={items} />
  ) : (
    <MenuButton title={title} href={href} />
  )
}

const MenuButton = ({ title, href, className, ...props }) => {
  return (
    <a
      className={`${className} ${classesMenu} py-3 px-4`}
      href={href}
      {...props}
    >
      {title}
    </a>
  )
}

const ClickableMenuButton = ({
  title,
  onClick,
  className,
  endIcon,
  ...props
}) => {
  return (
    <button
      className={`${className} ${classesMenu} py-3 px-4`}
      onClick={onClick}
      {...props}
    >
      <span className="flex flex-row justify-between w-full items-center">
        <span>{title}</span>
        {endIcon}
      </span>
    </button>
  )
}

const MenuPopover = ({ title, items }) => {
  return (
    <Popover className="relative">
      {({ open }) => (
        <>
          <Popover.Button
            className={`
            ${open ? "bg-gold-lighter" : ""} ${classesMenu} py-2 pl-4 pr-2`}
          >
            <span>{title}</span>
            <ChevronDownIcon
              className={`${open ? "" : "text-opacity-70"} ml-2 h-5 w-5`}
              aria-hidden="true"
            />
          </Popover.Button>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-1"
          >
            <Popover.Panel
              className={`absolute z-10 w-screen max-w-sm px-4 mt-3 transform -translate-x-1/2 left-1/2 sm:px-0 ${
                items.length > 3 ? "lg:max-w-3xl" : "lg:max-w-sm"
              }`}
            >
              <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
                <div
                  className={`relative grid gap-8 bg-white p-7 ${
                    items.length > 3 ? "lg:grid-cols-2" : "lg:grid-cols-1"
                  }`}
                >
                  {items.map(item => (
                    <a
                      key={item.title}
                      href={item.href}
                      className="flex items-center p-2 -m-3 transition duration-150 ease-in-out rounded-lg group hover:bg-silver-light focus:outline-none focus-visible:ring focus-visible:ring-gold focus-visible:ring-opacity-50"
                    >
                      <div className="flex items-center justify-center flex-shrink-0 w-10 h-10 text-white sm:h-12 sm:w-12">
                        <div className="relative bg-jet p-2 rounded">
                          <Icon
                            name={item.icon}
                            className="w-7 h-7 text-gold group-hover:text-silver"
                          />
                        </div>
                      </div>
                      <div className="ml-4 font-semibold leading-none text-sm flex flex-col justify-evenly h-full">
                        <p className="font-semibold text-jet mb-1">
                          {item.title}
                        </p>
                        <p className="font-medium text-jet-light leading-normal">
                          {item.description}
                        </p>
                      </div>
                    </a>
                  ))}
                </div>
              </div>
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  )
}

NavBarMenu.propTypes = {
  title: PropTypes.string.isRequired,
  href: PropTypes.string,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      description: PropTypes.string,
      href: PropTypes.string.isRequired,
      icon: PropTypes.string.isRequired,
    })
  ),
}

NavBarMenu.defaultProps = {
  title: ``,
  href: `/`,
}

export default NavBarMenu
export { MenuButton, ClickableMenuButton }
