import React from "react"
import PropTypes from "prop-types"

const getColorClass = bLight => {
  return bLight
    ? `text-jet placeholder-silver bg-silver-light`
    : `text-silver-light placeholder-silver bg-silver-dark`
}

const getBorderColorClass = bErrored => {
  return bErrored ? "ring-semantic-red ring-2" : ""
}

const getCursorClass = bDisabled => {
  return bDisabled ? `cursor-not-allowed` : `cursor-text`
}

const TextArea = ({
  name,
  value,
  placeholder,
  light,
  disabled,
  errored,
  onChange,
  ...props
}) => {
  return (
    <textarea
      className={`w-full h-24 p-4 text-xs font-semibold leading-none resize-none rounded ${getColorClass(
        light
      )} ${getCursorClass(disabled)} ${getBorderColorClass(
        errored
      )} outline-none`}
      type="text"
      placeholder={placeholder}
      value={value}
      name={name}
      disabled={disabled}
      onChange={onChange}
      {...props}
    ></textarea>
  )
}

TextArea.propTypes = {
  name: PropTypes.string,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  light: PropTypes.bool,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
}

TextArea.defaultProps = {
  placeholder: ``,
  light: false,
  disabled: false,
}

export default TextArea
