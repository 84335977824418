import React, { useState } from "react"
import PropTypes from "prop-types"
import { useController } from "react-hook-form"

const getBorderColorClass = bErrored => {
  return bErrored ? "ring-semantic-red ring-2" : ""
}

const getBGColorClass = bLight => {
  return bLight
    ? `bg-silver-light placeholder-silver`
    : `bg-silver-dark placeholder-silver`
}

const getButtonColorClass = (bLight, bDisabled) => {
  return bLight
    ? `${
        bDisabled ? "bg-silver-dark" : "bg-silver hover:bg-silver-dark"
      } text-jet`
    : `${bDisabled ? "bg-silver" : "bg-silver-light hover:bg-silver"} text-jet`
}

const getCursorClass = bDisabled => {
  return bDisabled ? `cursor-not-allowed` : `cursor-pointer`
}

const FileSelect = ({
  name,
  placeholder,
  light,
  disabled,
  errored,
  control,
  ...props
}) => {
  const { field } = useController({
    control,
    name,
    rules: {
      required: true,
    },
  })
  const [value, setValue] = useState("")
  const valueParts = value.split(/[/\\]/)
  const fileName = valueParts[valueParts.length - 1]

  return (
    <label
      className={`flex px-2 ${getBGColorClass(light)} ${getCursorClass(
        disabled
      )} ${getBorderColorClass(errored)} rounded`}
    >
      <input
        {...props}
        className="hidden"
        name={name}
        value={value}
        disabled={disabled}
        type="file"
        onChange={e => {
          setValue(e.target.value)
          field.onChange(e.target.files[0])
        }}
      />
      <span
        className={`my-auto text-xs font-semibold ${
          value ? "text-jet-light" : "text-silver"
        } px-2`}
      >
        {value ? fileName : placeholder}
      </span>
      <div
        className={`my-1 ml-auto px-4 py-3 text-xs font-semibold leading-none ${getButtonColorClass(
          light,
          disabled
        )} rounded transition duration-300 ease-in-out`}
      >
        Browse
      </div>
    </label>
  )
}

FileSelect.propTypes = {
  name: PropTypes.string,
  placeholder: PropTypes.string,
  light: PropTypes.bool,
  disabled: PropTypes.bool,
}

FileSelect.defaultProps = {
  placeholder: ``,
  type: "text",
  light: false,
  disabled: false,
}

export default FileSelect
