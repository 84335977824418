import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import { NavBar } from "./navigation"
import AitiLogo from "../images/svg/logo-normal-full.inline.svg"
import SectionFooter from "./sections/SectionFooter"

const Layout = ({ location, title, children }) => {
  const rootPath = `${__PATH_PREFIX__}/`
  const isRootPath = location?.pathname === rootPath
  const data = useStaticQuery(graphql`
    {
      siteYaml(type: { eq: "common" }) {
        navigation {
          menus {
            title
            href
            items {
              description
              href
              title
              icon
            }
          }
          actionPrimary {
            href
            title
          }
        }
      }
    }
  `)

  const { menus, actionPrimary, actionSecondary } = data.siteYaml.navigation

  return (
    <div
      className="antialiased bg-body text-body font-body text-jet"
      data-is-root-path={isRootPath}
    >
      <header
        className={`${
          isRootPath ? "bg-silver-light" : "bg-white"
        } top-0 sticky z-50`}
      >
        <NavBar
          title={title}
          bgColor={isRootPath ? "silver-light" : "white"}
          logo={<AitiLogo className="w-24" />}
          menus={menus}
          actionPrimary={actionPrimary}
          actionSecondary={actionSecondary}
        />
      </header>
      <main>{children}</main>
      <footer>
        <SectionFooter />
      </footer>
    </div>
  )
}

export default Layout
