import React from "react"
import PropTypes from "prop-types"

const getBGColorClass = bLight => {
  return bLight ? `bg-silver-light` : `bg-silver-dark`
}

const getTextColorClass = bLight => {
  return bLight
    ? `text-jet placeholder-silver`
    : `text-silver-light placeholder-silver`
}

const getBorderColorClass = bErrored => {
  return bErrored ? "ring-semantic-red ring-2" : ""
}

const getCursorClass = bDisabled => {
  return bDisabled ? `cursor-not-allowed` : `cursor-text`
}

const Input = ({
  className,
  name,
  value,
  placeholder,
  type,
  light,
  disabled,
  errored,
  startIcon,
  onChange,
  ...props
}) => {
  const StartIcon = startIcon
  return (
    <div
      className={`${className} flex w-full px-3 ${getBGColorClass(
        light
      )} ${getTextColorClass(light)} ${getBorderColorClass(errored)} rounded`}
    >
      {startIcon && (
        <StartIcon className={`h-6 w-6 my-auto ${getTextColorClass(light)}`} />
      )}
      <input
        className={`w-full pl-3 py-4 text-xs font-semibold leading-none ${getBGColorClass(
          light
        )} ${getTextColorClass(light)} ${getCursorClass(
          disabled
        )} outline-none`}
        type={type}
        name={name}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        {...props}
      />
    </div>
  )
}

Input.propTypes = {
  className: PropTypes.string,
  startIcon: PropTypes.any,
  name: PropTypes.string,
  value: PropTypes.string,
  type: PropTypes.oneOf(["text", "email"]),
  placeholder: PropTypes.string,
  light: PropTypes.bool,
  errored: PropTypes.bool,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
}

Input.defaultProps = {
  className: ``,
  placeholder: ``,
  type: "text",
  light: false,
  disabled: false,
}

export default Input
