import React from "react"
import PropTypes from "prop-types"
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/solid"
import { Link } from "gatsby"

const Pagination = ({
  className,
  previousPagePath,
  nextPagePath,
  currentPageNumber,
  totalPageCount,
}) => {
  return (
    <div className={`${className} flex flex-wrap items-center`}>
      <Link
        to={previousPagePath}
        className={`rounded ${
          previousPagePath
            ? "hover:bg-gold cursor-pointer text-jet"
            : "pointer-events-none text-jet-lighter"
        } transition duration-150 ease-in-out`}
      >
        <ChevronLeftIcon className="w-6 h-6 m-3" />
      </Link>
      <div className="flex flex-wrap items-center text-jet text-sm mx-3">
        <span className="mx-2 font-bold">{currentPageNumber}</span>
        <span>of</span>
        <span className="mx-2 font-bold">{totalPageCount}</span>
      </div>
      <Link
        to={nextPagePath}
        className={`rounded ${
          nextPagePath
            ? "hover:bg-gold cursor-pointer text-jet"
            : "pointer-events-none text-jet-lighter"
        } transition duration-150 ease-in-out`}
      >
        <ChevronRightIcon className="w-6 h-6 m-3 rounded" />
      </Link>
    </div>
  )
}

Pagination.propTypes = {
  className: PropTypes.string,
  previousPagePath: PropTypes.string.isRequired,
  nextPagePath: PropTypes.string.isRequired,
  currentPageNumber: PropTypes.number.isRequired,
  totalPageCount: PropTypes.number.isRequired,
}

Pagination.defaultProps = {
  className: ``,
}

export default Pagination
