import React, { useState } from "react"
import AitiLightLogo from "../../images/svg/logo-light.inline.svg"
import { graphql, Link, useStaticQuery } from "gatsby"
import { Icon, Message } from "../../components/display"
import { Button, Input } from "../inputs"
import { MailIcon } from "@heroicons/react/solid"
import { useRequest } from "ahooks"
import addToMailchimp from "gatsby-plugin-mailchimp"
import { useForm } from "react-hook-form"

const submitContact = ({ email }) => {
  return addToMailchimp(email)
}

const SubscribeForm = ({ data }) => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm()

  const [submitted, setSubmitted] = useState(false)
  const [submitErrored, setSubmitErrored] = useState(false)
  const [errorMessage, setErrorMessage] = useState("")
  const { loading, run } = useRequest(submitContact, {
    manual: true,
    onSuccess: ({ result, msg }) => {
      if (result === "success") {
        setSubmitErrored(false)
        setSubmitted(true)
        reset()
      } else {
        setSubmitErrored(true)
        setSubmitted(false)
      }
      setErrorMessage(msg)
    },
  })

  return (
    <div className="w-full lg:w-1/2 h-full flex flex-col text-xs space-y-4 items-start mb-8">
      <form
        className="w-full"
        form="subscription"
        onSubmit={handleSubmit(data => run(data))}
      >
        <div className="font-display text-base font-bold text-center md:text-left w-full pb-2">
          {data.title}
        </div>
        <div className="flex flex-wrap w-full max-w-xl space-x-4">
          <Input
            placeholder={data.action.placeholder}
            className="flex-1"
            startIcon={MailIcon}
            errored={!!errors.email}
            {...register("email", {
              required: true,
              pattern:
                /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i,
            })}
          />
          <Button
            className="mx-auto"
            type="submit"
            size="large"
            disabled={loading || submitted}
          >
            {data.action.title}
          </Button>
        </div>
      </form>
      {errorMessage && (
        <Message className="mt-4" type={submitErrored ? "error" : "success"}>
          <p dangerouslySetInnerHTML={{ __html: errorMessage }} />
        </Message>
      )}
    </div>
  )
}

const Footer = () => {
  const data = useStaticQuery(graphql`
    {
      siteYaml(type: { eq: "common" }) {
        footer {
          subscribe {
            title
            action {
              placeholder
              title
            }
          }
          social {
            href
            icon
            title
          }
          legal {
            title
            items {
              title
              href
            }
          }
          quick_links {
            title
            items {
              title
              href
            }
          }
        }
      }
    }
  `)
  const { subscribe, social, legal, quick_links } = data.siteYaml.footer

  return (
    <section className="py-16 bg-jet text-silver">
      <div className="container px-4 mx-auto">
        <div className="py-4 flex flex-col-reverse items-center md:flex-row md:justify-between">
          <Link
            to="/"
            className="inline-block mx-auto py-3 md:mb-0 md:ml-0 md:mr-auto"
          >
            <AitiLightLogo className="h-8" />
          </Link>
          <div className="mb-8 md:mb-0">
            {social.map(({ title, href, icon }) => (
              <a
                key={href}
                href={href}
                className="inline-block px-2"
                title={title}
              >
                <Icon
                  name={icon}
                  className="text-silver hover:text-silver-light w-5 mx-1 duration-150 ease-in-out"
                />
              </a>
            ))}
          </div>
        </div>
        <div className="flex flex-col md:flex-row-reverse items-stretch md:justify-between flex-wrap py-8">
          <SubscribeForm data={subscribe} />
          <div className="w-full md:w-1/2 lg:w-1/4 h-full flex flex-col text-xs space-y-4 items-center md:items-start mb-8">
            <h6 className="font-display text-base font-bold">{legal.title}</h6>
            {legal.items.map(({ title, href }) => (
              <Link key={title} to={href} className="hover:text-silver-light">
                {title}
              </Link>
            ))}
          </div>
          <div className="w-full md:w-1/2 lg:w-1/4 h-full flex flex-col text-xs space-y-4 items-center md:items-start mb-8">
            <h6 className="font-display text-base font-bold">
              {quick_links.title}
            </h6>
            {quick_links.items.map(({ title, href }) => (
              <Link key={title} to={href} className="hover:text-silver-light">
                {title}
              </Link>
            ))}
          </div>
        </div>
        <div className="flex flex-col items-center md:flex-row justify-center lg:justify-start space-x-6 my-3 text-xs">
          <p className="inline-block mt-2">
            &copy; {new Date().getFullYear()}. All rights reserved.
          </p>
          <span className="hidden md:inline-block mt-2">•</span>
          <Link
            to="/sitemap/sitemap-index.xml"
            className="hover:text-silver-light mt-2"
          >
            Sitemap
          </Link>
          <span className="hidden md:inline-block mt-2">•</span>
          <a href="https://mi8y.com" className="hover:text-silver-light mt-2">
            Made with{" "}
            <span role="img" aria-label="love">
              ❤️
            </span>{" "}
            by Mi8y
          </a>
        </div>
      </div>
    </section>
  )
}

export default Footer
