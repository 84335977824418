import React from "react"
import PropTypes from "prop-types"
import { Popover, Transition } from "@headlessui/react"
import { ChevronDownIcon, MenuAlt3Icon } from "@heroicons/react/solid"
import { Fragment } from "react"
import { Button } from "../inputs"
import { ClickableMenuButton, MenuButton } from "./NavBarMenu"
import { Link } from "gatsby"
import { useToggle } from "ahooks"

const SubMenu = ({ title, items }) => {
  const [isMenuOpen, { toggle: toggleExpand }] = useToggle(false)

  return (
    <div>
      <ClickableMenuButton
        className="w-full"
        title={title}
        onClick={() => toggleExpand()}
        endIcon={<ChevronDownIcon className="w-4 h-4 text-jet-light" />}
      />
      <ExpandingSubMenus show={isMenuOpen} items={items} />
    </div>
  )
}

const ExpandingSubMenus = ({ show, items }) => {
  return (
    <Transition
      enter="transition-height duration-150"
      enterFrom="h-0"
      enterTo="h-full"
      leave="transition-height duration-150"
      leaveFrom="h-full"
      leaveTo="h-0"
      show={show}
    >
      <div className="flex flex-col space-y-2 my-2 py-2 ml-4 bg-silver-light border-l-2 border-gold">
        {items.map(({ title, href }) => (
          <MenuButton title={title} href={href} className="w-full" />
        ))}
      </div>
    </Transition>
  )
}

const NavPopup = ({ menus, actionPrimary, actionSecondary }) => {
  return (
    <div>
      <Popover className="relative">
        {({ open }) => (
          <>
            <Popover.Button
              className={`
                ${open ? "bg-silver-light" : ""}
                navbar-burger flex items-center py-2 px-3 hover:bg-silver-light rounded hover:border-gold-light focus:outline-none focus-visible:ring focus-visible:ring-gold focus-visible:ring-opacity-50`}
            >
              <MenuAlt3Icon className="h-5 w-5 text-silver-dark" />
            </Popover.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel className="absolute z-10 w-80 px-4 mt-3 transform -right-4 sm:px-0">
                <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
                  <div className="p-4 bg-white">
                    {menus.map(({ title, href, items }) => (
                      <div key={title} className="pb-2">
                        {items ? (
                          <SubMenu title={title} items={items} />
                        ) : (
                          <MenuButton
                            title={title}
                            href={href}
                            className="w-full"
                          />
                        )}
                      </div>
                    ))}
                    <div className="pb-2">
                      {actionSecondary && (
                        <Button
                          component={Link}
                          to={actionSecondary.href}
                          variant="outlined"
                          className="w-full"
                        >
                          {actionSecondary.title}
                        </Button>
                      )}
                    </div>
                    <div>
                      <Button
                        component={Link}
                        className="w-full"
                        to={actionPrimary.href}
                      >
                        {actionPrimary.title}
                      </Button>
                    </div>
                  </div>
                </div>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
    </div>
  )
}

NavPopup.propTypes = {
  menus: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      href: PropTypes.string,
      items: PropTypes.arrayOf(
        PropTypes.shape({
          title: PropTypes.string.isRequired,
          href: PropTypes.string,
        })
      ),
    })
  ),
  actionPrimary: PropTypes.shape({
    title: PropTypes.string.isRequired,
    href: PropTypes.string,
  }),
  actionSecondary: PropTypes.shape({
    title: PropTypes.string.isRequired,
    href: PropTypes.string,
  }),
}

export default NavPopup
