import React from "react"
import PropTypes from "prop-types"
import AitiIcon from "../../images/svg/icon-normal.inline.svg"
import LinkedInIcon from "../../images/svg/linkedin.inline.svg"
import YouTubeIcon from "../../images/svg/youtube.inline.svg"
import FacebookIcon from "../../images/svg/facebook.inline.svg"
import InstagramIcon from "../../images/svg/instagram.inline.svg"
import TwitterIcon from "../../images/svg/twitter.inline.svg"
import IncorporationIcon from "../../images/svg/corporation.inline.svg"
import AccountingIcon from "../../images/svg/accounting.inline.svg"
import EfficiencyIcon from "../../images/svg/efficiency.inline.svg"
import TransactionIcon from "../../images/svg/money.inline.svg"
import BPOIcon from "../../images/svg/process.inline.svg"
import BalanceScaleIcon from "../../images/svg/balance-scale.inline.svg"
import CustomerIcon from "../../images/svg/customer.inline.svg"
import TeamIcon from "../../images/svg/team.inline.svg"
import RiskIcon from "../../images/svg/risk.inline.svg"
import ImagePredictiveAnalytics from "../../images/svg/predictive-analytics.inline.svg"
import ImageGrowthMetrics from "../../images/svg/growth-metrics.inline.svg"
import ImageSharedWorkspace from "../../images/svg/shared-workspace.inline.svg"
import ImageLightBulbMoment from "../../images/svg/light-bulb-moment.inline.svg"
import ImagePencil from "../../images/svg/pencil.inline.svg"
import ImageValue from "../../images/svg/value.inline.svg"
import ImageBackInTime from "../../images/svg/back-in-time.inline.svg"
import ImageWinner from "../../images/svg/winner.inline.svg"
import ImageAccuracy from "../../images/svg/accuracy.inline.svg"
import ImageCompliant from "../../images/svg/compliant.inline.svg"
import ImageEmployee from "../../images/svg/employee.inline.svg"
import ImageEmployees from "../../images/svg/employees.inline.svg"
import ImageRiskAlert from "../../images/svg/risk-alert.inline.svg"
import ImageServiceIncorporation from "../../images/svg/service-incorporation.inline.svg"
import ImageServiceAccounting from "../../images/svg/service-accounting.inline.svg"
import ImageServiceCFO from "../../images/svg/service-cfo.inline.svg"
import ImageServiceBPO from "../../images/svg/service-bpo.inline.svg"
import ImageHappiness from "../../images/svg/happiness.inline.svg"
import ImagePark from "../../images/svg/park.inline.svg"
import ImageGoal from "../../images/svg/goal.inline.svg"
import ImageGettingKnow from "../../images/svg/approach-getting-know.inline.svg"
import ImagePartners from "../../images/svg/approach-partners.inline.svg"
import ImageSolutions from "../../images/svg/approach-solutions.inline.svg"
import ImagePlanning from "../../images/svg/planning.inline.svg"

// currently only use one of the icons from a map
const Icon = ({ name, className }) => {
  switch (name) {
    case "balance-scale.inline.svg":
      return <BalanceScaleIcon className={className} />
    case "customer.inline.svg":
      return <CustomerIcon className={className} />
    case "team.inline.svg":
      return <TeamIcon className={className} />
    case "risk.inline.svg":
      return <RiskIcon className={className} />
    case "corporation.inline.svg":
      return <IncorporationIcon className={className} />
    case "accounting.inline.svg":
      return <AccountingIcon className={className} />
    case "efficiency.inline.svg":
      return <EfficiencyIcon className={className} />
    case "money.inline.svg":
      return <TransactionIcon className={className} />
    case "process.inline.svg":
      return <BPOIcon className={className} />
    case "predictive-analytics.inline.svg":
      return <ImagePredictiveAnalytics className={className} />
    case "growth-metrics.inline.svg":
      return <ImageGrowthMetrics className={className} />
    case "shared-workspace.inline.svg":
      return <ImageSharedWorkspace className={className} />
    case "light-bulb-moment.inline.svg":
      return <ImageLightBulbMoment className={className} />
    case "pencil.inline.svg":
      return <ImagePencil className={className} />
    case "value.inline.svg":
      return <ImageValue className={className} />
    case "back-in-time.inline.svg":
      return <ImageBackInTime className={className} />
    case "winner.inline.svg":
      return <ImageWinner className={className} />
    case "accuracy.inline.svg":
      return <ImageAccuracy className={className} />
    case "compliant.inline.svg":
      return <ImageCompliant className={className} />
    case "employee.inline.svg":
      return <ImageEmployee className={className} />
    case "employees.inline.svg":
      return <ImageEmployees className={className} />
    case "risk-alert.inline.svg":
      return <ImageRiskAlert className={className} />
    case "happiness.inline.svg":
      return <ImageHappiness className={className} />
    case "park.inline.svg":
      return <ImagePark className={className} />
    case "planning.inline.svg":
      return <ImagePlanning className={className} />
    case "goal.inline.svg":
      return <ImageGoal className={className} />
    case "service-incorporation.inline.svg":
      return <ImageServiceIncorporation className={className} />
    case "service-accounting.inline.svg":
      return <ImageServiceAccounting className={className} />
    case "service-cfo.inline.svg":
      return <ImageServiceCFO className={className} />
    case "service-bpo.inline.svg":
      return <ImageServiceBPO className={className} />
    case "approach-getting-know.inline.svg":
      return <ImageGettingKnow className={className} />
    case "approach-partners.inline.svg":
      return <ImagePartners className={className} />
    case "approach-solutions.inline.svg":
      return <ImageSolutions className={className} />
    case "facebook.inline.svg":
      return <FacebookIcon className={className} />
    case "instagram.inline.svg":
      return <InstagramIcon className={className} />
    case "twitter.inline.svg":
      return <TwitterIcon className={className} />
    case "linkedin.inline.svg":
      return <LinkedInIcon className={className} />
    case "youtube.inline.svg":
      return <YouTubeIcon className={className} />
    default:
      return <AitiIcon className={className} />
  }
}

Icon.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string.isRequired,
}

Icon.defaultProps = {
  className: ``,
  name: `default`,
}

export default Icon
