import React from "react"
import PropTypes from "prop-types"

const getSizeClass = sSize => {
  switch (sSize) {
    case "large":
      return `py-4 px-8`
    case "small":
      return `py-2 px-3`
    default:
      return `py-3 px-4`
  }
}

const getVariantColorClass = (sVariant, disabled) => {
  switch (sVariant) {
    case "contained":
      return disabled
        ? `cursor-not-allowed text-jet-light bg-jet-lighter focus:ring-jet-lighter focus:ring-opacity-50`
        : `cursor-pointer text-jet bg-gold hover:bg-gold-light active:bg-gold-dark focus:ring-gold focus:ring-opacity-50`
    case "contained-gold-inverse":
      return disabled
        ? `cursor-not-allowed text-jet-light bg-jet-lighter focus:ring-jet-lighter focus:ring-opacity-50`
        : `cursor-pointer text-jet bg-gold hover:bg-jet-light hover:text-gold active:bg-jet-darker focus:ring-jet focus:ring-opacity-50`
    case "contained-jet-inverse":
      return disabled
        ? `cursor-not-allowed text-jet-light bg-jet-lighter focus:ring-jet-lighter focus:ring-opacity-50`
        : `cursor-pointer text-gold bg-jet hover:bg-gold-dark hover:text-jet active:bg-gold-darker focus:ring-gold focus:ring-opacity-50`
    case "outlined":
      return disabled
        ? `cursor-not-allowed text-jet-lighter border border-jet-lighter focus:ring-jet-lighter focus:ring-opacity-50`
        : `cursor-pointer text-jet border border-gold hover:bg-gold-lighter active:bg-gold-light focus:ring-gold focus:ring-opacity-50`
    default:
      return disabled
        ? `cursor-not-allowed text-jet-lighter focus:ring-jet-lighter focus:ring-opacity-50`
        : `cursor-pointer text-jet hover:bg-gold-lighter active:bg-gold-light focus:ring-gold-light focus:ring-opacity-50`
  }
}

const Button = ({
  className,
  component,
  size,
  disabled,
  variant,
  children,
  onClick,
  ...props
}) => {
  const Component = component
  return (
    <Component
      disabled={disabled}
      className={`${className} inline-block rounded text-xs text-center font-semibold leading-none font-display ${getSizeClass(
        size
      )} ${getVariantColorClass(
        variant,
        disabled
      )} focus:outline-none focus:ring-2 transition duration-300 ease-in-out`}
      onClick={onClick}
      {...props}
    >
      {children}
    </Component>
  )
}

Button.propTypes = {
  className: PropTypes.string,
  component: PropTypes.any,
  size: PropTypes.oneOf(["normal", "large", "small"]),
  variant: PropTypes.oneOf([
    "contained",
    "contained-gold-inverse",
    "contained-jet-inverse",
    "outlined",
    "text",
  ]),
  disabled: PropTypes.bool,
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func.isRequired,
}

Button.defaultProps = {
  className: ``,
  component: `button`,
  size: `normal`,
  variant: `contained`,
  disabled: false,
  children: `Button`,
  onClick: () => {},
}

export default Button
