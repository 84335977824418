import React from "react"
import PropTypes from "prop-types"

const getColorClass = bInversed => {
  return bInversed ? `text-gold bg-jet-light` : `text-jet bg-gold-light`
}

const Badge = ({ children, inversed, ...props }) => {
  return (
    <span
      className={`text-xs py-1 px-3 font-semibold rounded-xl ${getColorClass(
        inversed
      )}`}
      {...props}
    >
      {children}
    </span>
  )
}

Badge.propTypes = {
  children: PropTypes.node.isRequired,
  inversed: PropTypes.bool,
}

Badge.defaultProps = {
  children: "Badge",
  inversed: false,
}

export default Badge
